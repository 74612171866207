import { CgArrowUp } from "react-icons/cg";
import styled from "styled-components";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { useEffect, useState } from "react";

const ToTopWrapper = styled.div`
position: fixed;
font-size: 25px;
color: #fff;
width: 50px;
height: 50px;
padding: 13px;
background: #0c9a5e;
border-radius: 50%;
cursor: pointer;
opacity: 0;
visibility: hidden;
transition: .5s;

&.on {
    opacity: 1;
    visibility: visible;


    @media (min-width: 1024px) {
        bottom: 50px;
        right: 50px;
    }
    
    @media screen and (max-width: 1024px) {
        /* 모바일 스타일 */
        position: fixed;
        bottom: 20px;
        right: 20px;
        font-size: 25px;
        color: #fff;
        width: 50px;
        height: 50px;
        padding: 13px;
        background: #0c9a5e;
        border-radius: 50%;
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        transition: .5s;

        &.on {
            opacity: 1;
            visibility: visible;
            }
}

`
const ToTop = () => {

    const [scr, setScr] = useState(0);
    //scr = 스크롤값 
    useEffect(() => {
        //서버에서 데이타 가져올 때
        //window 이벤트 있을 때
        gsap.registerPlugin(ScrollToPlugin);
    }, []);

    const scrollHandler = () => {
        let sct = window.scrollY;
        setScr(sct);
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        }
    }, [])

    const ToTopHandler = () => {
        gsap.to(window, { duration: 0.5, scrollTo: 0 });
    }
    return (
        <ToTopWrapper onClick={ToTopHandler} className={scr > 400 ? 'on' : ''}>
            <CgArrowUp />
        </ToTopWrapper>
    )
}
export default ToTop;