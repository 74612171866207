import {useEffect, useRef, useState} from 'react'
import RewardVariationsGraph from '../componets/RewardVariationsGraph'
import PoolHashTrendGraph from '../componets/PoolHashTrendGraph'
import 'animate.css'
import Background from '../componets/Background'

const Dashboard = () => {
    const timerRef = useRef()
    const [data, setData] = useState()
    const [nextBlock, setNextBlock] = useState(0)

    const fetchData = async () => {
        const data = await fetch('/api/dashboard').then(res => res.json())
        setData(data)
        setNextBlock(data.nextBlock)
    }

    const numberWithCommas = (x) => {
        if(!x) return 0;
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleCount = () => {
        if (nextBlock <= 0) {
            void fetchData()
        } else {
            setNextBlock(nextBlock => nextBlock - 1)
        }
    }

    useEffect(() => {
        void fetchData()
    }, [])

    useEffect(() => {
        timerRef.current = setInterval(handleCount, 1000)

        return () => {
            clearInterval(timerRef.current)
        }
    }, [nextBlock])

    if (!data) {
        return
    }

    return (
        <html lang="UTF-8">
            <head>
            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="author" content="MBTCs" />
            <meta name="description" content="MBTCs Dashboard" />

            <title>MBTCs Dashboard</title>
            <link rel="stylesheet" href="/dashboard/css/animate.css" />
            <link rel="stylesheet" href="/dashboard/css/initial.css" />
            <link rel="stylesheet" href="/dashboard/css/layout.css" />
        </head>
            <body>
            <header>
                <div className="logo-header wow fadeInDown animate__animated" data-wow-duration="1.2s">
                    <h1><img src="/dashboard/img/logo.svg" alt="MBTCs Logo" className="logo" /></h1>
                </div>
            </header>
            <main>
                <h2 className="wow fadeInUp animate__animated" data-wow-duration="1.2s">
                    <em className="italic">{'{'}MBTCs{'}'}</em> DASHBOARD
                </h2>
                <section className="dashboard">
                    <div
                        className="card card-blue wow zoomIn animate__animated"
                        data-wow-delay="0.1s"
                        data-wow-duration="1.2s"
                    >
                        <h4>Total Hash</h4>
                        <div className="card-value">
                            {numberWithCommas(data?.totalHash)}<small className="unit">th</small>
                        </div>
                        <div className="card-subtitle">Total hash volume</div>
                    </div>
                    <div
                        className="card card-orange wow zoomIn animate__animated"
                        data-wow-delay="0.2s"
                        data-wow-duration="1.2s"
                    >
                        <h4>Total NFT</h4>
                        <div className="card-value">{numberWithCommas(data?.totalNft)}<small className="unit">ea</small></div>
                        <div className="card-subtitle">Total NFT quantity</div>
                    </div>
                    <div
                        className="card card-blue wow zoomIn animate__animated"
                        data-wow-delay="0.3s"
                        data-wow-duration="1.2s"
                    >
                        <h4>Next Block</h4>
                        <div className="card-value time-lft">
                            {numberWithCommas(nextBlock / 60 | 0)}<small className="unit">min</small> {nextBlock % 60 | 0}<small className="unit">sec</small>
                            Left
                        </div>
                        <div className="card-subtitle">Time left until next block</div>
                    </div>
                    <div
                        className="card wow zoomIn animate__animated"
                        data-wow-delay="0.4s"
                        data-wow-duration="1.2s"
                    >
                        <h4>Next Halving Left</h4>
                        <div className="card-value">{numberWithCommas(data?.nextHalvingLeft)}<small className="unit">days</small></div>
                        <div className="card-subtitle">Days left until next halving</div>
                    </div>
                    <div
                        className="card wow zoomIn animate__animated"
                        data-wow-delay="0.5s"
                        data-wow-duration="1.2s"
                    >
                        <h4>Pool Hash</h4>
                        <div className="card-value">
                            {numberWithCommas(data?.poolHash)}<small className="unit">th</small>
                        </div>
                        <div className="card-subtitle">Active hash amount</div>
                    </div>
                    <div
                        className="card wow zoomIn animate__animated"
                        data-wow-delay="0.6s"
                        data-wow-duration="1.2s"
                    >
                        <h4>Staked NFT</h4>
                        <div className="card-value">
                            {numberWithCommas(data?.stakedNft)}<small className="unit">ea</small>
                        </div>
                        <div className="card-subtitle">Staking NFT</div>
                    </div>
                    <div
                        className="card wow zoomIn animate__animated"
                        data-wow-delay="0.7s"
                        data-wow-duration="1.2s"
                    >
                        <h4>Average Mining Reward (days)</h4>
                        <div className="card-value">
                            {Number(data?.averageMiningReward).toFixed(4)}<small className="unit">MBTCs / 50,000Th</small>
                        </div>
                        <div className="card-subtitle">MBTCs mined based on 50,000Th</div>
                    </div>
                    <div
                        className="card card-orange wow zoomIn animate__animated"
                        data-wow-delay="0.8s"
                        data-wow-duration="1.2s"
                    >
                        <h4>Block Reward (10Min)</h4>
                        <div className="card-value">{numberWithCommas(data?.blockReward)}<small className="unit">MBTCs</small></div>
                        <div className="card-subtitle">Quantity of MBTCs mined in one block</div>
                    </div>
                </section>
                <section className="graphs">
                    <RewardVariationsGraph/>
                    <PoolHashTrendGraph/>
                </section>

                <div
                    className="circle-text-container wow fadeIn animate__animated"
                    data-wow-delay="0.6s"
                    data-wow-duration="1.2s"
                >
                    <svg width="140" height="140" viewBox="0 0 300 300">
                        <defs>
                            <path
                                id="circlePath"
                                d="M 150, 150 m -120, 0 a 120,120 0 1,1 240,0 a 120,120 0 1,1 -240,0"
                            />
                        </defs>
                        <text fill="#fff" fontSize="20" fontFamily="Arial">
                            <textPath id="circleTextPath" href="#circlePath" startOffset="0%">
                                Data is located at the bottom Data is located at the bottom
                            </textPath>
                        </text>
                    </svg>
                    <div className="arrow-container">
                        <svg className="arrow" viewBox="0 0 24 24">
                            <path d="M12 18l-6-6h12l-6 6z" fill="#000" />
                        </svg>
                    </div>
                </div>

                <Background/>
            </main>
            {/*<footer>*/}
            {/*  <address>*/}
            {/*    <p>Address: ABC-115, Albasma Business Center, Deira, Dubai, UAE</p>*/}
            {/*    <p>Email: mbtcs.official@gmail.com</p>*/}
            {/*  </address>*/}
            {/*  <p>COPYRIGHT &copy; MBTCs. ALL RIGHTS RESERVED.</p>*/}
            {/*  <select name="familySite" id="familySite">*/}
            {/*    <option value="">Family Site</option>*/}
            {/*  </select>*/}
            {/*</footer>*/}
            <script type="text/javascript" src="/dashboard/js/menu.js"></script>
            <script type="text/javascript" src="/dashboard/js/ani.js"></script>
            <script>
                new WOW().init()
            </script>
            </body>
        </html>
    )
}

export default Dashboard
