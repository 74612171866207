import {useEffect, useRef} from 'react'

const Background = () => {
    const backgroundRef = useRef()

    function handleScroll() {
        const scrollThreshold = 200 // 스크롤 기준 픽셀 값

        if (window.scrollY > scrollThreshold) {
            backgroundRef.current.style.display = "none" // 스크롤이 기준을 초과하면 배경 요소 숨김
        } else {
            backgroundRef.current.style.display = "block" // 기준 미만일 경우 배경 요소 보임
        }
    }

    useEffect(() => {
        document.addEventListener("scroll", handleScroll)
    }, [])
    return (
        <div ref={backgroundRef} className="bg">
            <figure
                className="bg-diagram-v1 wow fadeInRight animate__animated"
                data-wow-delay="0.5s"
                data-wow-duration="2s"
            >
                <img src="/dashboard/img/bg_diagram_v1.svg" alt="Diagram version 1"/>
                <figcaption className="scout">Diagram version 1</figcaption>
            </figure>
            <figure
                className="bg-diagram-v2 wow fadeInRight animate__animated"
                data-wow-delay="0.6s"
                data-wow-duration="2s"
            >
                <img src="/dashboard/img/bg_diagram_v2.svg" alt="Diagram version 2"/>
                <figcaption className="scout">Diagram version 2</figcaption>
            </figure>
        </div>
    )
}

export default Background
