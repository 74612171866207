import React, { useEffect, useRef, useState, useCallback } from "react"
import "../css/component/header.scss"

// 반응형 조건 줄 때 사용했음 / ex) pc에는 있고 m에는 없을 때
import { useMediaQuery } from "react-responsive"

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl"

const Header = ({
  navItems,
  onNavItemClick,
  activeNavItem,
  handleLocaleChange,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const HeaderScroll = useRef()
  const [activeNavItemState, setActiveNavItem] = useState(activeNavItem)
  const [ignoreScrollEvent, setIgnoreScrollEvent] = useState(false)

  // --- { 모바일, 테블릿일 때 햄버거 버튼을 누르고 메뉴가 나오면 스크롤 이벤트가 안 되게}
  const cover = useRef(null)
  const wheelStop = (e) => {
    e.preventDefault()
  }
  // --- }

  // --- { 스크롤 내렸을 때 header className에 active를 붙이는 코드}
  useEffect(() => {
    const handleScroll = () => {
      if (ignoreScrollEvent) return // 스크롤 이벤트 무시 플래그

      let sct = window.scrollY
      if (sct > 0) {
        HeaderScroll.current?.classList.add("active")
      } else {
        HeaderScroll.current?.classList.remove("active")
      }

      // 스크롤에 따라 activeNavItem 업데이트
      const currentSection = navItems.find((item) => {
        const section = item.componentRef?.current
        if (section) {
          const rect = section.getBoundingClientRect()
          return (
            rect.top <= window.innerHeight / 2 &&
            rect.bottom >= window.innerHeight / 2
          )
        }
        return false
      })

      if (currentSection) {
        setActiveNavItem(currentSection.id) // 현재 스크롤 위치에 따라 메뉴 활성화 업데이트
      }
    }

    // 스크롤 이벤트 최적화를 위해 Debounce 적용
    const debouncedHandleScroll = debounce(handleScroll, 100)

    window.addEventListener("scroll", debouncedHandleScroll)

    if (cover.current) {
      cover.current.addEventListener("wheel", wheelStop)
    }

    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll)

      if (cover.current) {
        cover.current.removeEventListener("wheel", wheelStop)
      }
    }
  }, [navItems, ignoreScrollEvent])

  // --- { 언어 설정 버튼
  const [selectedButton, setSelectedButton] = useState("en-US")
  const handleButtonClick = (button) => {
    setSelectedButton(button) // selectedButton 값 업데이트
    handleLocaleChange(button) // handleLocaleChange 호출
  }
  // --- }

  // --- { 모바일, 햄버거 버튼 토글
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = () => {
    setIsOpen(!isOpen) // 현재 상태의 반대값으로 변경 (토글 기능)
  }
  // --- }

  // - { 모바일, 메뉴 클릭하면 창이 닫히고 그 부분으로 스크롤 이동
  const handleMenuClick = (navItemId) => {
    setIgnoreScrollEvent(true) // 스크롤 이벤트 무시
    onNavItemClick(navItemId)
    setIsOpen(false) // 메뉴 클릭 시 isOpen 상태를 닫힌 상태로 업데이트
    setActiveNavItem(navItemId) // 메뉴 클릭 후 active 상태 즉시 업데이트
    setTimeout(() => {
      setIgnoreScrollEvent(false) // 일정 시간 후 스크롤 이벤트 재활성화
    }, 300) // 딜레이 시간
  }
  // --- }

  // --- { Debounce 함수 - 스크롤 이벤트 최적화}
  function debounce(func, delay) {
    let timer
    return function (...args) {
      clearTimeout(timer) // 이전 타이머 제거
      timer = setTimeout(() => func.apply(this, args), delay) // delay 후 함수 호출
    }
  }
  // --- }

  return (
    <header className="header" ref={HeaderScroll}>
      <section className="header_wrap">
        <h1 className="logo">
          <a href="/">
            <img
              src={process.env.PUBLIC_URL + "/assets/logo_w.png"}
              alt="Logo"
            />
          </a>
        </h1>

        <nav className="gnb">
          <ul>
            {navItems.map((navItem) => (
              <li
                key={navItem.id}
                className={activeNavItemState === navItem.id ? "active" : ""}
                onClick={() => handleMenuClick(navItem.id)}
              >
                {navItem.label}
              </li>
            ))}
          </ul>
        </nav>

        {/* 모바일일 때 */}
        {isMobile ? (
          <div className="m_lang_hambergur_wrap">
            <div
              className={`mopen ${isOpen ? "active" : ""}`}
              ref={cover}
              onClick={handleClick}
            >
              <button></button>
              {isOpen && (
                <nav className="aisde_menu">
                  <ul>
                    {navItems.map((navItem) => (
                      <li
                        key={navItem.id}
                        className={
                          activeNavItemState === navItem.id ? "active" : ""
                        }
                        // 메뉴 클릭하면 창이 닫히고 그 부분으로 스크롤 이동
                        onClick={() => handleMenuClick(navItem.id)}
                      >
                        {navItem.label}
                      </li>
                    ))}
                  </ul>
                </nav>
              )}
            </div>
          </div>
        ) : null}
      </section>
    </header>
  )
}

export default Header
