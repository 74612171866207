import Graph from '../componets/Graph'

const RewardVariationsGraph = () => {
    return (
        <div
            className="graph-reward wow fadeIn"
            data-wow-delay="0.5s"
            data-wow-duration="1.2s"
        >
            <div className="graph-header">
                <h3>Reward Variations</h3>
            </div>

            <div className="graph-content">
                <Graph label={'Reward Variations'} valueName={'average_mining_reward'} color={'#FF9534'}/>
            </div>
        </div>
    )
}

export default RewardVariationsGraph
