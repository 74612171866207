import Graph from '../componets/Graph'

const PoolHashTrendGraph = () => {
    return (
        <div
            className="graph-poolHash wow fadeIn"
            data-wow-delay="0.6s"
            data-wow-duration="1.2s"
        >
            <div className="graph-header">
                <h3>Pool Hash Trend</h3>
            </div>
            <div className="graph-content">
                <Graph label={'Pool Hash Trend'} valueName={'pool_hash'} color={'#32A2DB'}/>
            </div>
        </div>
    )
}

export default PoolHashTrendGraph
