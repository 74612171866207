import React, { useState } from "react"
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps"

import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { Tooltip } from "react-tooltip"

const geoUrl = "/features.json"

const ambassadors = [
  {
    flag: "/assets/flags/fi.png",
    markerOffset: -30,
    coordinates: [22.89575, 62.52806],
    date: "Oct. 23. 2024",
    id: "358241023",
    name: "Dan****",
    country: "Finland",
    nftIds: [],
  },
  {
    flag: "/assets/flags/fi.png",
    markerOffset: -30,
    coordinates: [22.89575, 57.52806],
    date: "Oct. 22. 2024",
    id: "358241022",
    name: "Muh**********",
    country: "Finland",
    nftIds: [],
  },
  {
    flag: "/assets/flags/kr.png",
    markerOffset: -30,
    coordinates: [132.89575, 37.52806],
    date: "Sep. 19. 2024",
    id: "82240919",
    name: "WOO******",
    country: "REPUBLIC OF KOREA",
    nftIds: [],
  },
  {
    flag: "/assets/flags/id.png",
    markerOffset: -30,
    coordinates: [118.34626, -5.420932],
    date: "Aug. 20. 2024",
    id: "062240819",
    name: "PT.****",
    country: "Indonesia",
    nftIds: [],
  },
  {
    flag: "/assets/flags/vn.png",
    markerOffset: -30,
    coordinates: [108.34626, 10.420932],
    date: "Jul. 31. 2024.",
    id: "084240731",
    name: "VuHo*******",
    country: "VietNam",
    nftIds: [],
  },
  {
    flag: "/assets/flags/jp.png",
    markerOffset: -30,
    coordinates: [133.34626, 28.420932],
    date: "Jul. 07. 2024.",
    id: "081240717",
    name: "HAR****",
    country: "JAPAN",
    nftIds: [],
  },
  {
    flag: "/assets/flags/us.png",
    markerOffset: -30,
    coordinates: [-112.34626, 28.420932],
    date: "Jul. 05. 2024.",
    id: "001240705",
    name: "Rap****",
    country: "LA_USA",
    nftIds: [],
  },
  {
    flag: "/assets/flags/ph.png",
    markerOffset: -30,
    coordinates: [119.6272658, 12.2889616],
    date: "Jun. 10. 2024.",
    id: "063240610",
    name: "Low***",
    country: "PHILIPPINES",
    nftIds: [],
  },
  {
    flag: "/assets/flags/it.png",
    markerOffset: -30,
    coordinates: [9.6272658, 35.2889616],
    date: "Jan. 17. 2024.",
    id: "039240117",
    name: "ALES***",
    country: "ITALIA",
    nftIds: [],
  },
  {
    flag: "/assets/flags/pk.png",
    markerOffset: -30,
    coordinates: [62.872855, 23.5393916],
    date: "Mar. 05. 2024.",
    id: "092240305",
    name: "BRO***",
    country: "PAKISTAN",
    nftIds: [],
  },
  // {
  //   flag: "/assets/flags/kr.png",
  //   markerOffset: -30,
  //   coordinates: [126.89575, 37.52806],
  //   date: "Feb. 01. 2024.",
  //   id: "082240201",
  //   name: "Buen***",
  //   country: "REPUBLIC OF KOREA",
  //   nftIds: [70000],
  // },
  {
    flag: "/assets/flags/gh.png",
    markerOffset: -30,
    coordinates: [-3.260786, 4.5392525],
    date: "Feb. 01. 2024.",
    id: "233240201",
    name: "Dani***",
    country: "GHANA",
    nftIds: [100000],
  },
  {
    flag: "/assets/flags/cm.png",
    markerOffset: -30,
    coordinates: [8.3822176, 1.6546659],
    date: "Jan. 17. 2024.",
    id: "237240117",
    name: "Hail***",
    country: "CAMEROON",
    nftIds: [90000],
  },
  {
    flag: "/assets/flags/cy.png",
    markerOffset: -30,
    coordinates: [25.6451285, 27.4823018],
    date: "Dec. 11. 2023.",
    id: "357231211",
    name: "HENR***",
    country: "KYPROS",
    nftIds: [9999, 99999],
  },
  {
    flag: "/assets/flags/ae.png",
    markerOffset: -30,
    coordinates: [55.2707828, 25.2048493],
    date: "Jan. 08. 2024.",
    id: "971240108",
    name: "John***",
    country: "DUBAI",
    nftIds: [11, 22],
  },
  {
    flag: "/assets/flags/ae.png",
    markerOffset: -30,
    coordinates: [42.67413, 20.980417],
    date: "Feb. 21. 2024.",
    id: "971240221",
    name: "akt***",
    country: "RAS AL-KHAIMAH",
    nftIds: [],
  },
  {
    flag: "/assets/flags/ae.png",
    markerOffset: -30,
    coordinates: [52.34626, 28.420932],
    date: "Feb. 05. 2024.",
    id: "971240205",
    name: "Ri***",
    country: "Sharjah_UAE",
    nftIds: [],
  },
]

const nfts = [
  {
    image: "https://raw.seadn.io/files/11229a1e01a203fe3b827d321c617bc7.jpg",
    num: 1,
    collection: "VIP 05",
    th: "-",
    ownershipStatus: "Dubai",
  },
  {
    image: "https://raw.seadn.io/files/9f2cf763d983bb334c06a6673bdb1e6c.jpg",
    num: 2,
    collection: "VIP 05",
    th: "-",
    ownershipStatus: "Dubai",
  },
  {
    image: "https://raw.seadn.io/files/eced43f6f6a968a354da146744e298c7.jpg",
    num: 3,
    collection: "VIP 05",
    th: "-",
    ownershipStatus: "Abu Dhabi_UAE",
  },
  {
    image: "https://raw.seadn.io/files/09797bd24c8a0b4a56a15c7f3552dfed.jpg",
    num: 5,
    collection: "VIP 05",
    th: "-",
    ownershipStatus: "Abu Dhabi_UAE",
  },
  {
    image: "https://raw.seadn.io/files/180efc38ac221fa3608abe1aac56ddc4.jpg",
    num: 7,
    collection: "VIP 05",
    th: "-",
    ownershipStatus: "Republic of Korea",
  },
  {
    image: "https://raw.seadn.io/files/ff6b9f972a928580ef2b19f6b5119f52.jpg",
    num: 8,
    collection: "VIP 05",
    th: "-",
    ownershipStatus: "Hong Kong",
  },
  {
    image: "https://raw.seadn.io/files/c1aad41541904017bd8a4f96e20783ac.jpg",
    num: 9,
    collection: "VIP 05",
    th: "-",
    ownershipStatus: "INDIA",
  },
  {
    image: "https://raw.seadn.io/files/a5b5db0e75fa18b1a21bceacc2e28b7c.jpg",
    num: 11,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "JOH***_Dubai",
  },
  {
    image: "https://raw.seadn.io/files/db1f763adf364928d0bb85ec5de93e54.jpg",
    num: 111,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "SONG***_Singapore",
  },
  {
    image: "https://raw.seadn.io/files/84daf3d2596047e81587905ad67e57e3.jpg",
    num: 1111,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "LEE SU***_Republic of Korea",
  },
  {
    image: "https://raw.seadn.io/files/809bf06d99a6647d424d329dc736e51d.jpg",
    num: 11111,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "JI***_Republic of Korea",
  },
  {
    image: "https://raw.seadn.io/files/e22f646864d1b2e9e8ae1c58a9e0bfcb.jpg",
    num: 1004,
    collection: "VIP 04",
    th: "1,000,000",
    ownershipStatus: "MO***_Japan",
  },
  {
    image: "https://raw.seadn.io/files/ef929a5d7c411594e12ee8047ea6c2f3.jpg",
    num: 22,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "JOH***_Dubai",
  },
  {
    image: "https://raw.seadn.io/files/56db22fc761c1c946f964cdec0f6fae9.jpg",
    num: 222,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "SONG***_Singapore",
  },
  {
    image: "https://raw.seadn.io/files/b0027011ad31e25f020795e692d15c2b.jpg",
    num: 2222,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "LEE***_Republic of Korea",
  },
  {
    image: "https://mbtcsuper.com/assets/vip/22222.jpg",
    num: 22222,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "Available for sale",
  },
  {
    image: "https://raw.seadn.io/files/1cecbcb7c962e7df00fa771ff397ec5d.jpg",
    num: 33,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "Available for sale",
  },
  {
    image: "https://raw.seadn.io/files/400c2ed5d653ca33c828fb117dde53b5.jpg",
    num: 333,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "JANG***_Republic of Korea",
  },
  {
    image: "https://raw.seadn.io/files/7ce9884cf8e360454a6e7499b50e75fa.jpg",
    num: 3333,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "Available for sale",
  },
  {
    image: "https://raw.seadn.io/files/67920140a561539ca88379101760c698.jpg",
    num: 33333,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "HAZ***_Sharjah",
  },
  {
    image: "https://raw.seadn.io/files/e74ae5557c3c406f86666b7ea1cc5141.jpg",
    num: 55,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "LEE***_Republic of Korea",
  },
  {
    image: "https://raw.seadn.io/files/c060aa7c726e40063489b7670b8c67ef.jpg",
    num: 555,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "Lee****_Republic of Korea",
  },
  {
    image: "https://raw.seadn.io/files/f6def115dbfcc1d782b68ccec71cbcdf.jpg",
    num: 5555,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "Available for sale",
  },
  {
    image: "https://raw.seadn.io/files/4d57b6d7aa8ec45fc2de2a9d2d01b9fd.jpg",
    num: 55555,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "Available for sale",
  },
  {
    image: "https://raw.seadn.io/files/39c3be665dc8aec78cc5ca21891200f5.jpg",
    num: 77,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "WON***_Dubai",
  },
  {
    image: "https://raw.seadn.io/files/3657de2c1441a4fd082c1c83d2b2083e.jpg",
    num: 777,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "ALRI***_Dubai",
  },
  {
    image: "https://raw.seadn.io/files/23073c457edffc601284793de524628e.jpg",
    num: 7777,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "Available for sale",
  },
  {
    image: "https://raw.seadn.io/files/0655c0d8bc16136aad8501932caef284.jpg",
    num: 77777,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "ALRI***_Dubai",
  },
  {
    image: "https://raw.seadn.io/files/cab159c76b703ab55e14ebb6f61ac717.jpg",
    num: 88,
    collection: "VIP 03",
    th: "800,000",
    ownershipStatus: "Available for sale",
  },
  {
    image: "https://raw.seadn.io/files/b9f2889a05df437eb9784561c97aec7e.jpg",
    num: 888,
    collection: "VIP 03",
    th: "800,000",
    ownershipStatus: "Available for sale",
  },
  {
    image: "https://raw.seadn.io/files/9d23664b1a620c8804cf43aef22b34c7.jpg",
    num: 8888,
    collection: "VIP 03",
    th: "800,000",
    ownershipStatus: "Available for sale",
  },
  {
    image: "https://mbtcsuper.com/assets/vip/88888.jpg",
    num: 88888,
    collection: "VIP 03",
    th: "800,000",
    ownershipStatus: "Available for sale",
  },
  {
    image: "https://raw.seadn.io/files/3ebdf5ab6157c69e4fbfb0d268214f2d.jpg",
    num: 99,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "DO***_Republic of Korea",
  },
  {
    image: "https://raw.seadn.io/files/0d65521a71a63c25a407e63edf7b5892.jpg",
    num: 999,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "Lee****_Republic of Korea",
  },
  {
    image: "https://raw.seadn.io/files/91475de49368f24eec0dccefcd7f048b.jpg",
    num: 9999,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "HENR***_KYPROS",
  },
  {
    image: "https://raw.seadn.io/files/ff033f3d580111c833f8477d87009636.jpg",
    num: 99999,
    collection: "VIP 02",
    th: "200,000",
    ownershipStatus: "HENR***_KYPROS",
  },
  {
    image: "https://raw.seadn.io/files/89805c6fe35b36832454527ddff66743.jpg",
    num: 100,
    collection: "VIP 01",
    th: "50,000",
    ownershipStatus: "KIM***_Republic of Korea",
  },
  {
    image: "https://raw.seadn.io/files/9e6f8f840370e29fe1474a303980f8f9.jpg",
    num: 1000,
    collection: "VIP 01",
    th: "50,000",
    ownershipStatus: "NO***_Republic of Korea",
  },
  {
    image: "https://raw.seadn.io/files/0a40ae9aa256f060719608952dc12592.jpg",
    num: 10000,
    collection: "VIP 01",
    th: "50,000",
    ownershipStatus: "KIM***_Republic of Korea",
  },
  {
    image: "https://raw.seadn.io/files/d15d3f27c113a7a00ee9c5de394e9132.jpg",
    num: 20000,
    collection: "VIP 01",
    th: "50,000",
    ownershipStatus: "HAN***_Republic of Korea",
  },
  {
    image: "https://mbtcsuper.com/assets/vip/30000.jpg",
    num: 30000,
    collection: "VIP 01",
    th: "50,000",
    ownershipStatus: "COT***_Malaysia",
  },
  {
    image: "https://raw.seadn.io/files/73c367ab2168e96ad2b8d76981eec667.jpg",
    num: 40000,
    collection: "VIP 01",
    th: "50,000",
    ownershipStatus: "JANG***_Republic of Korea",
  },
  {
    image: "https://mbtcsuper.com/assets/vip/50000.jpg",
    num: 50000,
    collection: "VIP 01",
    th: "50,000",
    ownershipStatus: "COT***_Malaysia",
  },
  {
    image: "https://raw.seadn.io/files/dab1556db7a82313b840b6f7df0ab7f7.jpg",
    num: 60000,
    collection: "VIP 01",
    th: "50,000",
    ownershipStatus: "WOO***_Republic of Korea",
  },
  // {
  //   image: "https://raw.seadn.io/files/8606c802c4a4de3bee28ad4018a04171.jpg",
  //   num: 70000,
  //   collection: "VIP 01",
  //   th: "50,000",
  //   ownershipStatus: "BUEN***_Republic of Korea",
  // },
  {
    image: "https://raw.seadn.io/files/b4c4ef0c17fce91ebe1fd454ad07423b.jpg",
    num: 80000,
    collection: "VIP 01",
    th: "50,000",
    ownershipStatus: "JUDAN***_Republic of Korea",
  },
  {
    image: "https://mbtcsuper.com/assets/vip/90000.jpg",
    num: 90000,
    collection: "VIP 01",
    th: "50,000",
    ownershipStatus: "Hail***_Cameroon",
  },
  {
    image: "https://raw.seadn.io/files/26c4466494cff0518f80add21a60c090.jpg",
    num: 100000,
    collection: "VIP 01",
    th: "50,000",
    ownershipStatus: "Dani***_Ghana",
  },
]

const CustomTableContainer = styled(TableContainer)`
  min-height: 500px;
  max-height: 500px;

  table {
    min-width: 500px;
    min-height: 300px;
  }

  table > thead > tr > th {
    font-size: 14px;
  }

  table > tbody > tr > td {
    font-size: 12px;
  }
`

const WorldMap = () => {
  const [content, setContent] = useState("")
  return (
    <div className="ecosystem worldmap">
      <div className="inner">
        <div className="title">
          <h2>Global Ambassador & VIP NFT</h2>
          <p>
            The Global Ambassador community of MBTCs and its VIP NFT holders
            facilitate the expansion of MBTCs ecosystem and its innovations
            across the world, bringing more adoption and reach.
          </p>
        </div>
        <Tooltip
          id="my-tooltip"
          arrowColor="#FFFFFF"
          style={{ backgroundColor: "#FFFFFF" }}
        />
        <ComposableMap
        // projection="geoMercator"
        // projectionConfig={{
        //     rotate: [58, 20, 0],
        //         scale: 400
        //     }}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill="#171f22"
                  stroke="#202f30"
                  onMouseEnter={() => {
                    setContent(`${geo.properties.name}`)
                  }}
                  onMouseLeave={() => {
                    setContent("")
                  }}
                />
              ))
            }
          </Geographies>
          {ambassadors.map(
            ({ id, flag, country, name, coordinates, markerOffset }) => {
              const html = `
                                <div>
                                    <img
                                        src="${flag}"
                                        width="40"
                                        height="30"
                                        alt="${country}"/>
                                    &nbsp;&nbsp;<span style="color: #000;font-size:12px">${name}, ${id}, ${country}</span>
                                </div>
                            `

              return (
                <Marker key={name} coordinates={coordinates}>
                  <image
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html={html}
                    data-tooltip-place="top"
                    x="-20"
                    y="-30"
                    height="40"
                    href="/assets/map_pin2.png"
                  />
                </Marker>
              )
            }
          )}
        </ComposableMap>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h4" component="div">
                  {ambassadors.length} Ambassador
                </Typography>
              </CardContent>
            </Card>
            <CustomTableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">AMB ID</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Country</TableCell>
                    {/*<TableCell align="right">Owned VIP NFT</TableCell>*/}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ambassadors.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center" scope="row">
                        {row.date}
                      </TableCell>
                      <TableCell align="center">{row.id}</TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">{row.country}</TableCell>
                      {/*<TableCell align="right">*/}
                      {/*    {row.nftIds.join(',')}*/}
                      {/*    { row.nftIds.length === 0 && '- '}*/}
                      {/*</TableCell>*/}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CustomTableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h4" component="div">
                  VIP NFT
                </Typography>
              </CardContent>
            </Card>
            <CustomTableContainer component={Paper}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">NFT</TableCell>
                    <TableCell align="center">NFT Num</TableCell>
                    {/*<TableCell align="center">Collection</TableCell>*/}
                    <TableCell align="center">Ownership Status</TableCell>
                    <TableCell align="right">T/h</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {nfts.map((row) => (
                    <TableRow
                      key={row.num}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        <a href={row.image} target="_blank">
                          <img src={row.image} width="40px" />
                        </a>
                      </TableCell>
                      <TableCell align="center">{row.num}</TableCell>
                      {/*<TableCell align="center">{row.collection}</TableCell>*/}
                      <TableCell align="center">
                        {row.ownershipStatus}
                      </TableCell>
                      <TableCell align="right">{row.th}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CustomTableContainer>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default WorldMap
