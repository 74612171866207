import React, { useEffect, useRef, useState } from "react"
import AboutMBTCs from "../pages/AboutMBTCs"
import Ecosystem from "../pages/Ecosystem"
//import Foundation from "../pages/Foundation"
import Service from "../pages/Service"
import MallIntro from "../pages/MallIntro" // MallIntro 추가
import Partners from "../pages/Partners"
import News from "../pages/News"
import MainVisual from "../pages/MainVisual"
import WorldMap from "../pages/WorldMap"
import Header from "../componets/Header"
import Footer from "../componets/Footer"
import ToTop from "../componets/ToTop"

const Main = ({}) => {
  const defaultLocale = "en-US" // 기본 언어 설정
  const [locale, setLocale] = useState(defaultLocale)
  const componentRefs = {
    AboutMBTCs: useRef(null),
    WorldMap: useRef(null),
    Ecosystem: useRef(null),
    //Foundation: useRef(null),
    MallIntro: useRef(null), // MallIntro 추가
    Service: useRef(null),
    Partners: useRef(null),
    News: useRef(null),
    ContactUs: useRef(null),
    // 필요한 만큼 컴포넌트 ref를 추가합니다.
  }

  const navItems = [
    {
      id: "AboutMBTCs",
      label: "About MBTCs",
      componentRef: componentRefs.AboutMBTCs,
    },
    {
      id: "WorldMap",
      label: "Global Ambassador & VIP NFT",
      componentRef: componentRefs.WorldMap,
    },
    {
      id: "Ecosystem",
      label: "Ecosystem",
      componentRef: componentRefs.Ecosystem,
    },
    // {
    //   id: "Foundation",
    //   label: "Foundation",
    //   componentRef: componentRefs.Foundation,
    // },
    { id: "Service", label: "Service", componentRef: componentRefs.Service },
    {
      id: "MallIntro", // MallIntro 네비게이션 추가
      label: "Mall Introduction",
      componentRef: componentRefs.MallIntro,
    },
    { id: "Partners", label: "Partners", componentRef: componentRefs.Partners },
    { id: "News", label: "News", componentRef: componentRefs.News },
    // { id: 'ContactUs', label: 'Contact us' },
    // 필요한 다른 네비게이션 항목들을 추가합니다.
  ]

  const [activeNavItem, setActiveNavItem] = useState("AboutMBTCs")

  const scrollToComponent = (ref) => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }

  const handleNavItemClick = (componentName) => {
    scrollToComponent(componentRefs[componentName])
  }

  const handleScroll = () => {
    const scrollPosition = window.scrollY

    const activeItem = navItems.find((item, idx) => {
      if (idx === navItems.length - 1) return true
      return (
        scrollPosition >= item.componentRef.current.offsetTop &&
        scrollPosition < navItems[idx + 1].componentRef.current.offsetTop
      )
    })

    setActiveNavItem(activeItem.id)
  }

  useEffect(() => {
    // 스크롤 이벤트가 발생하기 전에 active 상태 초기화
    setActiveNavItem("fake")

    // 스크롤 이벤트 리스너 등록
    window.addEventListener("scroll", handleScroll)

    // 컴포넌트가 언마운트되면 스크롤 이벤트 리스너 해제
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleLocaleChange = (newLocale) => {
    setLocale(newLocale)
    localStorage.setItem("locale", newLocale)
  }

  useEffect(() => {
    if (!localStorage.getItem("locale")) {
      localStorage.setItem("locale", defaultLocale)
    }
  }, [])
  return (
    <>
      <Header
        navItems={navItems}
        onNavItemClick={handleNavItemClick}
        activeNavItem={activeNavItem}
        handleLocaleChange={handleLocaleChange}
      />
      <main>
        <section>
          <MainVisual />
        </section>
        <section ref={componentRefs.AboutMBTCs}>
          <AboutMBTCs />
        </section>
        <section ref={componentRefs.WorldMap}>
          <WorldMap />
        </section>
        <section ref={componentRefs.Ecosystem}>
          <Ecosystem />
        </section>
        {/* <section ref={componentRefs.Foundation}>
          <Foundation />
        </section> */}
        <section ref={componentRefs.Service}>
          <Service />
        </section>
        {/* MallIntro 섹션 추가 */}
        <section ref={componentRefs.MallIntro}>
          <MallIntro />
        </section>
        <section ref={componentRefs.Partners}>
          <Partners />
        </section>
        <section ref={componentRefs.News}>
          <News />
        </section>
      </main>
      <Footer />
      <ToTop />
    </>
  )
}

export default Main
