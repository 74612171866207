import GraphTabs from '../componets/GraphTabs'
import {Line} from 'react-chartjs-2'
import {useEffect, useState} from 'react'
import moment from 'moment/moment'
import 'chart.js/auto'


const Graph = ({label, valueName, color}) => {
    const [graphData, setGraphData] = useState()
    const [min, setMin] = useState(0)
    const [max, setMax] = useState(0)

    const fetchData = async (days) => {
        if(!days) {
            days = 30
        }

        const result = await fetch(`/api/dashboard?days=${days}`).then(res => res.json())

        const data = result.poolHashes.map((item) => item[valueName])
        setMin(Math.min(...data))
        setMax(Math.max(...data))

        setGraphData({
            labels: result.poolHashes.map((item) => moment(item.created_at).format("YY.MM.DD")), // x축 날짜들
            datasets: [
                {
                    label,
                    data, // 데이터 값
                    borderColor: color,
                    backgroundColor: color, // 범례 아이콘 배경색
                    fill: false,
                    tension: 0.3, // 선 곡률
                    pointRadius: 0, // 점 제거
                    borderWidth: 1, // 선 두께
                }
            ]
        })
    }

    useEffect(() => {
        void fetchData()
    }, [])

    if (!graphData) {
        return
    }

    const options = {
        responsive: true, // 반응형
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true, // y축 0부터 시작
                min: min - max * 0.01 , // y축 최소값
                max: max + max * 0.01, // y축 최대값
                ticks: {
                    stepSize: 100000, // y축 간격 3
                    callback: (value) => '', // y축 단위
                    font: { size: 14, family: "Pretendard" },
                },
                grid: {
                    color: "rgba(0, 0, 0, 0.1)", // y축 그리드 색
                    borderDash: [5, 5], // y축 대시 스타일
                },
            },
            x: {
                ticks: {
                    maxTicksLimit: 6, // x축 최대 레이블 6개
                    font: { size: 14, family: "Pretendard" },
                },
                grid: {
                    color: "rgba(0, 0, 0, 0.1)", // x축 그리드 색
                    borderDash: [5, 5], // x축 대시 스타일
                },
            },
        },
        plugins: {
            legend: {
                display: true, // 범례 표시
                position: "bottom", // 범례 위치
                align: "end", // 범례 정렬
                labels: {
                    usePointStyle: true, // 아이콘 점 모양
                    pointStyle: "circle", // 원형
                    boxWidth: 8, // 아이콘 너비
                    boxHeight: 8, // 아이콘 높이
                    padding: 20, // 범례 간격
                    color: "#888", // 범례 색상
                    font: { size: 14, family: "Pretendard" },
                },
            },
            tooltip: {
                enabled: true, // 툴팁 활성화
                mode: "index", // x축 기준 모든 데이터셋 표시
                intersect: false, // 포인트 근처에서도 표시
                backgroundColor: "rgba(0, 0, 0, 0.7)", // 툴팁 배경
                titleFont: { family: "Pretendard", size: 14 },
                bodyFont: { family: "Pretendard", size: 12 },
                bodyColor: "#fff",
                titleColor: "#fff",
                padding: 10,
                usePointStyle: true, // 툴팁 아이콘 동그라미
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || ""
                        if (label) label += ": "
                        label += context.raw
                        return label
                    },
                },
            },
        },
    }

    const onUpdateTab = async (tab) => {
        void fetchData(tab)
    }

    return (
        <>
            <GraphTabs updateTab={onUpdateTab}/>
            <Line data={graphData} options={options}/>
        </>
    )
}

export default Graph
