import React from "react"
import "../css/component/ecosystem.scss"
import { useMediaQuery } from "react-responsive"

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl"

const MallIntro = () => {
  // 모바일, 테블릿일 때만 슬라이드
  const isMobile = useMediaQuery({ minWidth: 280, maxWidth: 768 })
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 })

  return (
    <div className="ecosystem MallIntro">
      <div className="inner">
        <div className="title">
          <h2>EAST·SOUK Shopping Mall</h2>
          <p>
            <FormattedMessage id="MallIntro_Title01" />
            <br />
            <FormattedMessage id="MallIntro_Title02" />
          </p>
        </div>

        <div>
          {/* 모바일 일 때 이미지가 li 위로 올라오게 */}
          {isMobile || isTablet ? (
            <div className="ecosystem_text_wrap">
              <ul className="ecosystem_text">
                <a
                  href="https://eastsouk.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/assets/MallIntro_m.jpg"}
                    alt=""
                  />
                </a>
                <li>
                  <FormattedMessage id="MallIntro_text01" />
                </li>
                <li>
                  <FormattedMessage id="MallIntro_text02" />
                </li>
              </ul>
            </div>
          ) : (
            <div className="ecosystem_text_wrap">
              <ul className="ecosystem_text">
                <li>
                  <FormattedMessage id="MallIntro_text01" />
                </li>
                <li>
                  <FormattedMessage id="MallIntro_text02" />
                </li>
              </ul>
              <a
                href="https://eastsouk.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/MallIntro.jpg"}
                  alt=""
                />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

// note
{
  /* {index % 2 === 0 ? '01' : '02'}는 조건부 (ternary) 연산자를 사용한 표현식입니다.
이 표현식은 다음과 같은 의미를 가지고 있습니다:
index % 2 === 0: index를 2로 나눈 나머지가 0인 경우 (즉, 짝수인 경우)
? '01' : '02': 위의 조건이 참일 경우 '01'을 반환하고, 그렇지 않으면 '02'를 반환합니다.
즉, index가 짝수인 경우 '01', 홀수인 경우 '02'가 반환됩니다.
이 값을 사용하여 MallIntro_content_itm 클래스 이름을 동적으로 설정할 수 있습니다.
짝수 인덱스일 때는 'MallIntro_content_itm01' 클래스가 적용되고,
홀수 인덱스일 때는 'MallIntro_content_itm02' 클래스가 적용됩니다.
이를 통해 스타일링이나 렌더링을 다르게 구성할 수 있습니다. */
}

export default MallIntro
