import React from "react"
import "../css/component/ecosystem.scss"
import { useMediaQuery } from "react-responsive"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl"

const Ecosystem = () => {
  // 모바일, 테블릿일 때만 슬라이드
  const isMobile = useMediaQuery({ minWidth: 280, maxWidth: 768 })
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 })

  let slidesToShow = 3 // 기본 값 (데스크톱 사이즈)

  if (isMobile) {
    slidesToShow = 1
  } else if (isTablet) {
    slidesToShow = 2
  }

  const sliderSettings = {
    arrows: false,
    dots: true,
    slidesToShow: slidesToShow,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
  }

  const contentData = [
    /* {
      image: "ecosystem_con04.jpg",
      title: "MBTCs Asset_Real estate&Art&Mining",
      description: `In the past, traditional real estate transactions have carried significant 
        risk due to trading entire property ownership. However, MBTCs STO issues 
        real estate ownership in the form of securities, allowing for decentralized 
        investment. Investors receive dividends proportional to their investment, 
        thereby increasing accessibility to real estate investment.`,
      link: "/sto",
    },*/
    {
      image: "ecosystem_con01.jpg",
      title: <FormattedMessage id="Ecosystem_contentData_Title01" />,
      description: (
        <FormattedMessage id="Ecosystem_contentData_description01" />
      ),
    },
    {
      image: "ecosystem_con02.jpg",
      title: <FormattedMessage id="Ecosystem_contentData_Title02" />,
      description: (
        <FormattedMessage id="Ecosystem_contentData_description02" />
      ),
    },
    {
      image: "ecosystem_con03.jpg",
      title: <FormattedMessage id="Ecosystem_contentData_Title03" />,
      description: (
        <FormattedMessage id="Ecosystem_contentData_description03" />
      ),
    },
  ]

  return (
    <div className="ecosystem">
      <div className="inner">
        <div className="title">
          <h2>Ecosystem</h2>
          <p>
            <FormattedMessage id="Ecosystem_Title01" />
            <br />
            <FormattedMessage id="Ecosystem_Title02" />
          </p>
        </div>

        <div>
          {/* 모바일 일 때 이미지가 li 위로 올라오게 */}
          {isMobile || isTablet ? (
            <div className="ecosystem_text_wrap">
              <ul className="ecosystem_text">
                <img
                  src={process.env.PUBLIC_URL + "/assets/ecosystem_m.jpg"}
                  alt=""
                />
                <li>
                  <FormattedMessage id="Ecosystem_text01" />
                </li>
                <li>
                  <FormattedMessage id="Ecosystem_text02" />
                </li>
                <li>
                  <FormattedMessage id="Ecosystem_text03" />
                </li>
              </ul>
            </div>
          ) : (
            <div className="ecosystem_text_wrap">
              <ul className="ecosystem_text">
                <li>
                  <FormattedMessage id="Ecosystem_text01" />
                </li>
                <li>
                  <FormattedMessage id="Ecosystem_text02" />
                </li>
                <li>
                  <FormattedMessage id="Ecosystem_text03" />
                </li>
              </ul>
              <img
                src={process.env.PUBLIC_URL + "/assets/ecosystem.jpg"}
                alt=""
              />
            </div>
          )}
        </div>

        <div>
          {isMobile || isTablet ? (
            // 모바일, 테블릿 화면에서만 슬라이드를 표시하는 컴포넌트 렌더링
            <Slider {...sliderSettings}>
              {contentData.map((item, index) => (
                <a href={item.link}>
                  <div
                    className={`ecosystem_content_itm${
                      index % 2 === 0 ? "01" : "02"
                    } ecosystem_content_itm`}
                    key={index}
                  >
                    <div className="box">
                      <span className="img">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/${item.image}`}
                          alt=""
                        />
                      </span>
                      <strong>{item.title}</strong>
                      <p>{item.description}</p>
                      {index === 0 && (
                        <span className="small-btn" style={{ display: "none" }}>
                          Learn More{" "}
                          <span className="details-btn" href="/#">
                            <i className="fa-solid fa-arrow-right"></i>
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                </a>
              ))}
            </Slider>
          ) : (
            // 데스크톱 화면에서는 슬라이드 없이 내용만 표시
            <div>
              {/* 내용 */}
              <div className="ecosystem_content">
                {contentData.map((item, index) => (
                  <a href={item.link}>
                    <div
                      className={`ecosystem_content_itm${
                        index % 2 === 0 ? "01" : "02"
                      } ecosystem_content_itm`}
                      key={index}
                    >
                      <div className="gradient-border"></div>
                      <div className="box">
                        <span className="img">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/${item.image}`}
                            className="st-zoom-in"
                            alt=""
                          />
                        </span>
                        <strong>{item.title}</strong>
                        <p>{item.description}</p>
                        <span className="small-btn" style={{ display: "none" }}>
                          Learn More{" "}
                          <span className="details-btn" href="/#">
                            <i className="fa-solid fa-arrow-right"></i>
                          </span>
                        </span>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

// note
{
  /* {index % 2 === 0 ? '01' : '02'}는 조건부 (ternary) 연산자를 사용한 표현식입니다.
이 표현식은 다음과 같은 의미를 가지고 있습니다:
index % 2 === 0: index를 2로 나눈 나머지가 0인 경우 (즉, 짝수인 경우)
? '01' : '02': 위의 조건이 참일 경우 '01'을 반환하고, 그렇지 않으면 '02'를 반환합니다.
즉, index가 짝수인 경우 '01', 홀수인 경우 '02'가 반환됩니다.
이 값을 사용하여 ecosystem_content_itm 클래스 이름을 동적으로 설정할 수 있습니다.
짝수 인덱스일 때는 'ecosystem_content_itm01' 클래스가 적용되고,
홀수 인덱스일 때는 'ecosystem_content_itm02' 클래스가 적용됩니다.
이를 통해 스타일링이나 렌더링을 다르게 구성할 수 있습니다. */
}

export default Ecosystem
