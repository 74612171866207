import {
    Paper
} from '@mui/material'
import {styled} from '@mui/material/styles'

const LogoPaper = styled(Paper)`
    min-height: 120px;
    max-height: 120px;
    width: 192px;
    height: 192px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 90%;
        max-height: 90%;
    }
`

export default LogoPaper
