import {useState} from 'react'

const GraphTabs = ({updateTab}) => {
    const [tab, setTab] = useState('30')

    const handleTabClick = (tab) => {
        setTab(tab)
        updateTab(tab)
    }

    return (
        <nav className="tabs">
            <button
                {...tab === '30' && {className: 'active'}}
                onClick={() => handleTabClick('30')}
                data-target="rewardVar" data-timeframe="hour"
            >
                30 Days
            </button>
            <button
                {...tab === '90' && {className: 'active'}}
                onClick={() => handleTabClick('90')}
                data-target="rewardVar" data-timeframe="day">
                90 Days
            </button>
            <button
                {...tab === "180" && {className: "active"}}
                onClick={() => handleTabClick('180')}
                data-target="rewardVar" data-timeframe="month">
                180 Days
            </button>
        </nav>
    )
}

export default GraphTabs
