import { useEffect, useRef, useState } from "react";
import Slide from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/component/service.scss";
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import { useMediaQuery } from "react-responsive";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const Service = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const sliderSettings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    afterChange: (idx) => setSNum(idx),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const s = useRef(null);
  const [sNum, setSNum] = useState();
  useEffect(() => {
    setSNum(0);
  }, []);

  const slideData = [
    {
      image: "/assets/MBTCs_NFT_01.jpg",
      title: "MBTCs NFT Airdrop",
      hash: "30",
      usdt: "-",
    },
    {
      image: "/assets/MBTCs_NFT_02.jpg",
      title: "MBTCs NFT 01",
      hash: "500",
      usdt: "400",
    },
    {
      image: "/assets/MBTCs_NFT_03.jpg",
      title: "MBTCs NFT 02",
      hash: "1,300",
      usdt: "800",
    },
    {
      image: "/assets/MBTCs_NFT_04.jpg",
      title: "MBTCs NFT 03",
      hash: "3,000",
      usdt: "1,600",
    },
    {
      image: "/assets/MBTCs_NFT_05.jpg",
      title: "MBTCs NFT 04",
      hash: "18,000",
      usdt: "8,000",
    },
    {
      image: "/assets/MBTCs_NFT_06.jpg",
      title: "MBTCs NFT 05",
      hash: "50,000",
      usdt: "20,000",
    },
  ];

  const [isPrevActive, setIsPrevActive] = useState(false);
  const [isNextActive, setIsNextActive] = useState(true);

  const handlePrevClick = () => {
    s.current.slickPrev();
    setIsPrevActive(true);
    setIsNextActive(false);
  };

  const handleNextClick = () => {
    s.current.slickNext();
    setIsPrevActive(false);
    setIsNextActive(true);
  };

  return (
    <div className="service">
      <div className="inner">
        <div className="title">
          <h2>Service</h2>
          <p>
            <FormattedMessage id="Service_Title" />
          </p>
        </div>

        <div>
          <div className="app">
            <Link
              to="https://mbtcsmena.info/"
              target="_blank"
              className="app_text"
            >
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/MBTCs_coin_symbol_01.png"
                }
                alt=""
              />
              DApp browser
            </Link>
            <Link
              to="https://www.notion.so/Creating-an-MBTC-Wallet-Getting-Started-6b4433efd3ad445c8dcb4f45699e6139?pvs=4"
              target="_blank"
              className="app_text green"
            >
              How to Stake NFTs <i class="fa-solid fa-chevron-right"></i>
            </Link>
          </div>
        </div>
      </div>

      {isMobile ? (
        <>
          <Slide {...sliderSettings} ref={s}>
            {slideData.map((item, index) => (
              <div className="service_itm" key={index}>
                <figure>
                  <a
                    href="https://mbtcsmena.info/app/market"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={process.env.PUBLIC_URL + item.image}
                      alt={item.title}
                    />
                  </a>
                </figure>
                <div className="service_itm_text">
                  <strong>{item.title}</strong>
                  <div className="service_itm_text_flex">
                    <span>{item.hash}</span>
                    <b>T/h</b>
                  </div>
                  <div className="service_itm_text_flex">
                    <span>{item.usdt}</span>
                    <b>USDT</b>
                  </div>
                </div>
              </div>
            ))}
          </Slide>
          <div className="arrows">
            <button
              onClick={handlePrevClick}
              className={`prev btn ${isPrevActive ? "active" : ""}`}
            >
              <CgChevronLeft />
            </button>
            <button
              onClick={handleNextClick}
              className={`next btn ${isNextActive ? "active" : ""}`}
            >
              <CgChevronRight />
            </button>
          </div>
        </>
      ) : (
        <div className="list">
          {slideData.map((item, index) => (
            <div className="service_itm" key={index}>
              <figure>
                <a
                  href="https://mbtcsmena.info/app/market"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={process.env.PUBLIC_URL + item.image}
                    alt={item.title}
                  />
                </a>
              </figure>
              <div className="service_itm_text">
                <strong>{item.title}</strong>
                <div className="service_itm_text_flex">
                  <span>{item.hash}</span>
                  <b>T/h</b>
                </div>
                <div className="service_itm_text_flex">
                  <span>{item.usdt}</span>
                  <b>USDT</b>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="service_banner">
        <strong>
          <FormattedMessage id="Service_Banner" />
        </strong>
      </div>
    </div>
  );
};

export default Service;
